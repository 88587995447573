import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeStands = () => {
    return (
        <Layout>
                  <Seo
        title="Supports Pour Vélos - BIKESAFE"
        description="Tous les supports pour vélos que nous proposons, que ce soient des supports traditionnels ou stationnaires, sont adaptés à vos besoins individuels et vous offrent les prix les plus concurrentiels du marché."
      />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center uppercase">
                            Supports Pour Vélos
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="flow-text">
                            Tous les supports pour vélos que nous proposons, que ce soient des supports traditionnels ou stationnaires, sont adaptés à vos besoins individuels et vous offrent les prix les plus concurrentiels du marché.
                        </p>
                        <p className="flow-text">
                            Découvrez ci-dessous notre gamme de supports vélos classiques et nos supports pour vélos stationnaires  :</p>
                    </Col>
                </Row>
            </Container>
            <Container>
            <Row>
                <hr/>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Support vélos traditionnel</h3>
              <p>Ce design classique et très demandé représente une solution de stationnement pour vélos simple qui offre un bon rapport qualité-prix et nécessite une faible maintenance. </p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Support à arceau traditionnel noir</h3>
              <p> Sa forme permet d'accrocher le vélo au support au niveau du cadre et de la roue. Ce design classique et très demandé représente une solution de stationnement...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
        </Row>
            </Container>
        </Layout>
    )
}

export default BikeStands;